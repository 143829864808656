// src/pages/AuthCallback.js
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from './config.js';
import ErrorMessage from '../components/ErrorMessage';
import InstructionsModal from '../components/InstructionsModal';
import { AuthContext } from '../services/AuthContext';

function AuthCallback() {
  const navigate = useNavigate();
  const [authError, setAuthError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setIsAuthenticated, setUserData } = useContext(AuthContext);

  useEffect(() => {
    const exchangeCodeForToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          console.log('Exchanging code for token...');
          await axios.post(`${config.apiBaseUrl}/api/auth/callback`, { code });
          console.log('Token set in cookie by the server.');

          // Fetch user details to verify authentication
          const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
          setIsAuthenticated(true);
          setUserData(userResponse);
          // Redirect to the Library page upon successful authentication
          navigate('/login');
        } catch (error) {
          console.error('Error during authentication:', error);

          if (error.response) {
            if (error.response.status === 403) {
              // Specific handling for 403 Forbidden errors
              setAuthError('Authentication failed due to blocked cookies or browser extensions interfering with the login process.');
              setIsModalOpen(true);
            } else {
              // Handle other HTTP errors
              setAuthError(`Authentication failed: ${error.response.data.error || 'An unexpected error occurred.'}`);
            }
          } else if (error.request) {
            // Handle cases where the request was made but no response was received
            setAuthError('No response from the server. Please check your network connection and try again.');
          } else {
            // Handle other unexpected errors
            setAuthError(`Error: ${error.message}`);
          }
        }
      } else {
        console.error('No authorization code found in URL.');
        setAuthError('No authorization code found. Please try logging in again.');
      }
    };

    exchangeCodeForToken();
  }, [navigate]);

  return (
    <div>
      {authError ? (
        <>
          <ErrorMessage
            title="Authentication Error"
            message={authError}
            actions={
              <>
                <p>
                  Please ensure that cookies are enabled in your browser settings and that no extensions are blocking them.
                </p>
                <button onClick={() => setIsModalOpen(true)}>
                  How to Enable Cookies
                </button>
              </>
            }
          />
          <InstructionsModal 
            isOpen={isModalOpen} 
            onClose={() => setIsModalOpen(false)} 
          />
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default AuthCallback;
