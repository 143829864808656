// src/App.js
import React, { useEffect } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Admin from './pages/Admin';
import Library from './pages/Library';
import AuthCallback from './pages/AuthCallback';
import InspirationVote from './pages/InspirationVote';
import Infos from './pages/Infos';
import './global.css';
import { AuthProvider } from './services/AuthContext';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      localStorage.setItem('token', token);
      navigate('/library'); // Redirect to library after storing the token
    }
  }, [navigate]);

  return (
  <AuthProvider>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/library" element={<Library />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route path="/infos" element={<Infos />} />
      <Route path="/inspiration-vote" element={<InspirationVote  />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  </AuthProvider>
  );
}

export default App;
