import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from './config';
import Loader from '../components/Loader';
import './Login.css'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const passwordRequirements = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;

  const handleSendCode = async () => {
    if (!email) {
      return toast.error('Please enter your email.');
    }

    setLoading(true);
    try {
      await axios.post(`${config.apiBaseUrl}/api/auth/forgot-password`, { email });
      toast.success('If an account exists with that email, a reset code has been sent.');
      setStep(2);
    } catch (error) {
      console.error(error);
      toast.error('Failed to send reset code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (!email || !code || !newPassword) {
      return toast.error('Please fill all fields.');
    }
    if (!passwordRequirements.test(newPassword)) {
      return toast.error('Password must have at least 8 characters, 1 uppercase, and 1 special character.');
    }

    setLoading(true);
    try {
      await axios.post(`${config.apiBaseUrl}/api/auth/reset-password`, { email, code, newPassword });
      toast.success('Password reset successfully. You can now log in with your new password.');
      navigate('/login');
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.error || 'Failed to reset password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <ToastContainer />
      {loading && <Loader />}
      <div className="login-container">
        {step === 1 ? (
          <div className="login-form-container">
            <h1>Forgot Password</h1>
            <p>Enter your email to receive a reset code.</p>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button className="auth-button" onClick={handleSendCode}>Send Reset Code</button>
            <button className="auth-button" onClick={() => navigate('/login')}>Back to Login</button>
          </div>
        ) : (
          <div className="login-form-container">
            <h1>Reset Your Password</h1>
            <input
              type="email"
              placeholder="Enter your email again"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Enter your reset code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <p className="password-requirements">
              Password must have at least 8 characters, 1 uppercase, and 1 special character.
            </p>
            <button className="auth-button" onClick={handleResetPassword}>Reset Password</button>
            <button className="auth-button" onClick={() => setStep(1)}>Back</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
