import React, { useState, useEffect } from 'react';
import './PostCard.css'; // Import the CSS file
import kofiLogo from '../../assets/kofi_logo.png';
import patreonLogo from '../../assets/patreon_logo.png';
import greenTickIcon from '../../assets/green_tick_icon.png';
import lockedIcon from '../../assets/locked_icon.png';
import ApiService from '../../services/ApiService';
import config from '../config';
import { toast } from 'react-toastify'; // Import toast

const PostCard = ({
  post,
  postWidth,
  viewAsAdmin,
  updatePostsOrder,
  index,
  availableTiers,
}) => {
  const [editedPost, setEditedPost] = useState({ ...post });
  const [isEnabled, setIsEnabled] = useState(post.enabled);
  const [imageUrl, setImageUrl] = useState(post.imageUrl);
  const [newIndex, setNewIndex] = useState(index);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // State variables for admin controls
  const [selectedFirstTiers, setSelectedFirstTiers] = useState(post.first_tiers || []);
  const [selectedTiers, setSelectedTiers] = useState(post.tiers || []);
  const [isKofiOnly, setIsKofiOnly] = useState(post.is_kofiOnly || false);
  const [firstPublishedAt, setFirstPublishedAt] = useState(
    post.first_published_at ? new Date(post.first_published_at).toISOString().split('T')[0] : ''
  );

  useEffect(() => {
    setNewIndex(index);
  }, [index]);

  // Define the tiers in order of priority
  const tiersPriority = ['8824971', '8825009', '21786318']; // Tier 1, Tier 2, Tier 3

  // Map tier IDs to tier names
  const tierNames = {
    '8824971': 'Tier 1',
    '8825009': 'Tier 2',
    '21786318': 'Tier 3',
    // Add more mappings if you have more tiers
  };

  // Function to get the lowest tier that unlocks the item
  const getLowestTier = () => {
    for (const tierId of tiersPriority) {
      if (editedPost.tiers.includes(tierId)) {
        return tierId;
      }
    }
    return null; // None of the tiers are included
  };

  const lowestTier = getLowestTier();
  const version = post.version || 'V1.0';

  const handleIsKofiOnlyChange = (e) => {
    setIsKofiOnly(e.target.checked);
  };

  const handleDownload = (postId) => {
    // Display the toast notification
    toast.info('Download will be starting soon...');

    setIsDownloading(true);
    const downloadUrl = `${config.apiBaseUrl}/api/download/${postId}`;
    let iframe = document.getElementById('download_iframe');
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.id = 'download_iframe';
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }
    iframe.src = downloadUrl;

    // Optionally, reset the downloading state after some time
    setTimeout(() => {
      setIsDownloading(false);
      toast.success('Download initiated.');
    }, 3000); // Adjust the timeout as needed
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('image', file);

    try {
      await ApiService.uploadImage(post.postId, formData);
      setImageUrl(URL.createObjectURL(file));
      toast.success('Image uploaded successfully.');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    }
  };

  const handleEditChange = (field, value) => {
    setEditedPost({
      ...editedPost,
      [field]: value,
    });
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      const updatedPost = {
        ...editedPost,
        tiers: selectedTiers,
        first_tiers: selectedFirstTiers,
        first_published_at: firstPublishedAt ? new Date(firstPublishedAt) : null,
        is_kofiOnly: isKofiOnly,
      };
      await ApiService.updatePost(post.postId, updatedPost);
      setEditedPost({ ...updatedPost });
      toast.success('Changes saved successfully');
    } catch (error) {
      console.error('Error updating post:', error);
      toast.error('Failed to update post');
    } finally {
      setIsSaving(false);
    }
  };

  const handleToggleEnabled = async () => {
    try {
      const updatedEnabled = !isEnabled;
      await ApiService.togglePost(post.postId, updatedEnabled);
      setIsEnabled(updatedEnabled);
      toast.success(`Post has been ${updatedEnabled ? 'enabled' : 'disabled'}.`);
    } catch (error) {
      console.error('Error updating post enabled status:', error);
      toast.error('Failed to update post status');
    }
  };

  const handleIndexChange = async () => {
    if (newIndex === '' || isNaN(newIndex) || newIndex < 0) {
      toast.error('Please enter a valid index.');
      return;
    }
    await updatePostsOrder(post, parseInt(newIndex, 10));
  };

  // Booleans to check which buttons should be displayed
  const hasKofiButton = !!editedPost.kofi_id;
  const hasPaidPatreonButton =
    editedPost.url &&
    editedPost.tiers.some((tier) =>
      ['10477032', '8824971', '21786318', '8825009', '8652254'].includes(tier)
    ) &&
    editedPost.paid_content !== false;
  const hasFreePatreonButton = editedPost.paid_content === false && editedPost.url;

  // Handle changes to first_tiers selection (Checkboxes)
  const handleFirstTiersChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedFirstTiers([...selectedFirstTiers, value]);
    } else {
      setSelectedFirstTiers(selectedFirstTiers.filter((tierId) => tierId !== value));
    }
  };

  // Handle changes to tiers selection (Checkboxes)
  const handleTiersChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedTiers([...selectedTiers, value]);
    } else {
      setSelectedTiers(selectedTiers.filter((tierId) => tierId !== value));
    }
  };

  // Handle changes to first_published_at date
  const handleFirstPublishedAtChange = (e) => {
    const dateValue = e.target.value;
    setFirstPublishedAt(dateValue);
  };
  // Function to check if the post is new (published within the last 2 days)
  const isNew = () => {
    if (!post.first_published_at) return false;
    const firstPublishedDate = new Date(post.first_published_at);
    const now = new Date();
    const diffInDays = (now - firstPublishedDate) / (1000 * 60 * 60 * 24);
    return diffInDays <= 3; // 2 days
  };
  const isRecentlyUpdated = () => {
    // If the post is new, we don't display the "Updated" badge
    if (isNew()) return false;
    if (!post.last_updated) return false;
    const lastUpdatedDate = new Date(post.last_updated);
    const now = new Date();
    const diffInDays = (now - lastUpdatedDate) / (1000 * 60 * 60 * 24);
    return diffInDays <= 3; // Adjust the number of days as needed
  };

  return (
    <div className="post-card" style={{ width: postWidth }}>
      {/* Image Container */}
      <div className="image-container">
        {imageUrl ? (
          <img src={imageUrl} alt={editedPost.title} className="post-image" />
        ) : (
          <div className="no-image">
            <span>No preview picture found</span>
          </div>
        )}
        {/* Version Badge */}
        <div className="version-badge">
          <div className="badge-content">
            <div className="scrolling-text">
              <span>{version}</span>
            </div>
          </div>
        </div>

        {/* New Badge */}
        {isNew() && (
          <div className="new-badge">
            <div className="badge-content">
              <div className="new-scrolling-text">
                <span>New</span>
              </div>
            </div>
          </div>
        )}

        {/* Updated Badge */}
        {!isNew() && isRecentlyUpdated() && (
          <div className="updated-badge">
            <div className="badge-content">
              <div className="scrolling-text">
                <span>Updated</span>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Post Details */}
      <div className="post-details">
        {/* Actions and Download Container */}
        <div className="actions-download-container">
          {/* Left Side: Buttons */}
          <div className="buttons-container">
            {hasKofiButton && (
              <a
                href={`https://ko-fi.com/s/${editedPost.kofi_id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="action-button"
                title="Get it on Ko-fi"
              >
                <img src={kofiLogo} alt="Kofi Logo" className="logo-icon" />
                Shop
              </a>
            )}

            {/* Insert "Or" if both buttons are present */}
            {hasKofiButton && (hasPaidPatreonButton || hasFreePatreonButton) && (
              <span className="or-text">Or</span>
            )}

            {hasFreePatreonButton && (
              <a
                href={`https://patreon.com/${editedPost.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="action-button"
                title="Get it for free on Patreon"
              >
                <img src={patreonLogo} alt="Patreon Logo" className="logo-icon" />
                Free
              </a>
            )}

            {hasPaidPatreonButton && (
              <a
                href={`https://patreon.com/${editedPost.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="action-button"
                title={`Get it on Patreon with ${tierNames[lowestTier]} at least`}
              >
                <img src={patreonLogo} alt="Patreon Logo" className="logo-icon" />
                Subscribe ({tierNames[lowestTier]})
              </a>
            )}
          </div>

          {/* Right Side: Ownership and Download */}
          <div className="download-container">
            {editedPost.unlocked ? (
              <div className="ownership-container">
                <span>You own this item</span>
                <img src={greenTickIcon} alt="Green Tick" className="status-icon" />
                {editedPost.drive_file_id && (
                  <>
                    {isDownloading ? (
                      <span className="download-button">Download starting...</span>
                    ) : (
                      <button
                        onClick={() => handleDownload(editedPost.postId)}
                        className="download-button"
                      >
                        Download
                      </button>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="ownership-container">
                <img src={lockedIcon} alt="Locked Icon" className="status-icon-locked" />
              </div>
            )}
          </div>
        </div>

        {/* Admin View */}
        {viewAsAdmin && (
          <div className="post-card-admin-container">
            <span className="post-title">{editedPost.title}</span>
            {post.version && <p className="post-version">Version: {post.version}</p>}
            {isRecentlyUpdated() && <span className="new-badge">Updated!</span>}
            <div className="post-card-admin-actions">
              {/* Image Upload */}
              <div className="post-card-admin-section">
                <label>Change Image:</label>
                <input type="file" onChange={handleImageChange} />
              </div>

              {/* post-card-admin Inputs */}
              <div className="post-card-admin-section">
                {/* Kofi ID */}
                <div className="post-card-admin-input-group">
                  <label>Kofi ID:</label>
                  <input
                    type="text"
                    value={editedPost.kofi_id || ''}
                    onChange={(e) => handleEditChange('kofi_id', e.target.value)}
                  />
                </div>

                {/* Drive File ID */}
                <div className="post-card-admin-input-group">
                  <label>Drive File ID:</label>
                  <input
                    type="text"
                    value={editedPost.drive_file_id || ''}
                    onChange={(e) => handleEditChange('drive_file_id', e.target.value)}
                  />
                </div>

                {/* First Published At */}
                <div className="post-card-admin-input-group">
                  <label>First Published At:</label>
                  <input
                    type="date"
                    value={firstPublishedAt}
                    onChange={handleFirstPublishedAtChange}
                  />
                </div>

                {/* First Tiers (Checkboxes) */}
                <div className="post-card-admin-input-group">
                  <label>First Tiers:</label>
                  <div className="checkbox-group">
                    {availableTiers.map((tier) => (
                      <label key={tier.tierId} className="checkbox-label">
                        <input
                          type="checkbox"
                          value={tier.tierId}
                          checked={selectedFirstTiers.includes(tier.tierId)}
                          onChange={handleFirstTiersChange}
                        />
                        {tier.title}
                      </label>
                    ))}
                  </div>
                </div>

                {/* Tiers (Checkboxes) */}
                <div className="post-card-admin-input-group">
                  <label>Current Tiers:</label>
                  <div className="checkbox-group">
                    {availableTiers.map((tier) => (
                      <label key={tier.tierId} className="checkbox-label">
                        <input
                          type="checkbox"
                          value={tier.tierId}
                          checked={selectedTiers.includes(tier.tierId)}
                          onChange={handleTiersChange}
                        />
                        {tier.title}
                      </label>
                    ))}
                  </div>
                </div>
                {/* is_kofiOnly Toggle */}
                <div className="post-card-admin-input-group">
                  <label>Is Ko-fi Only:</label>
                  <input
                    type="checkbox"
                    checked={isKofiOnly}
                    onChange={handleIsKofiOnlyChange}
                  />
                </div>
                {/* Save Changes Button */}
                <button onClick={handleSaveChanges} className="save-button" disabled={isSaving}>
                  {isSaving ? 'Saving...' : 'Save'}
                </button>
              </div>

              {/* Enable/Disable Toggle */}
              <div className="post-card-admin-section">
                <label>Enable/Disable:</label>
                <button
                  onClick={handleToggleEnabled}
                  className={`toggle-button ${isEnabled ? 'enabled' : 'disabled'}`}
                >
                  {isEnabled ? 'Disable' : 'Enable'}
                </button>
              </div>

              {/* Index Setting */}
              <div className="post-card-admin-section">
                <label>Set Index:</label>
                <div className="index-input-group">
                  <input
                    type="number"
                    value={newIndex}
                    onChange={(e) => setNewIndex(e.target.value)}
                    min="0"
                  />
                  <button onClick={handleIndexChange} className="set-index-button">
                    Set
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostCard;
