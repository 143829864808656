import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../components/Navbar';
import { AuthContext } from '../services/AuthContext';
import InspirationPanel from '../components/InspirationPanel';
import ApiService from '../services/ApiService';
import Loader from '../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './InspirationVote.css';
import apiconfig from './config';


const InspirationVote = () => {
  const { isAuthenticated, userData } = useContext(AuthContext);
  const [panels, setPanels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewAsAdmin, setViewAsAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [title, setTitle] = useState('Inspiration vote!');
  const [editingTitle, setEditingTitle] = useState(false);
  const [config, setConfig] = useState({});
  const [timeLeft, setTimeLeft] = useState('');


  const [newPanel, setNewPanel] = useState({
    imageUrl: '',
  });
  const [editingPanel, setEditingPanel] = useState(null);

  // Fetch data when component mounts or when authentication status changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch panels and config
        const response = await ApiService.fetchInspirationPanels();
        let panelsData = response.data.panels;
        const configData = response.data.config;
  
        // Adjust imageUrl for each panel
        panelsData = panelsData.map((panel) => {
          if (panel.imageUrl.startsWith('https')) {
            return panel;
          } else {
            return {
              ...panel,
              imageUrl: `${apiconfig.apiBaseUrl}${panel.imageUrl}`,
            };
          }
        });
  
        setPanels(panelsData);
        setConfig(configData);
  
        // Set the title from config
        setTitle(configData.title || 'Inspiration Vote');
  
        // Set admin status
        if (isAuthenticated && userData) {
          setIsAdmin(userData.isAdmin);
        }
      } catch (error) {
        console.error('Error fetching inspiration data:', error);
        toast.error('Failed to fetch inspiration data.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [isAuthenticated, userData]);
  // Timer logic
  useEffect(() => {
    let timerInterval;
  
    if (config.votingOpen && config.votingEndTime) {
      timerInterval = setInterval(() => {
        const now = new Date();
        const endTime = new Date(config.votingEndTime);
        const diff = endTime.getTime() - now.getTime(); // Difference in milliseconds
      
        if (diff <= 0) {
          clearInterval(timerInterval);
          setTimeLeft('Voting has ended.');
        
          // Update the local config to reflect that voting is closed
          setConfig((prevConfig) => ({
            ...prevConfig,
            votingOpen: false,
          }));
        
          // Optionally, fetch the updated panels and config from the server
          const fetchLatestData = async () => {
            try {
              const response = await ApiService.fetchInspirationPanels();
              let panelsData = response.data.panels;
              const configData = response.data.config;
        
              // Adjust imageUrl for each panel
              panelsData = panelsData.map((panel) => {
                if (panel.imageUrl.startsWith('https')) {
                  return panel;
                } else {
                  return {
                    ...panel,
                    imageUrl: `${apiconfig.apiBaseUrl}${panel.imageUrl}`,
                  };
                }
              });
        
              setPanels(panelsData);
              setConfig(configData);
            } catch (error) {
              console.error('Error fetching latest data:', error);
            }
          };
          fetchLatestData();
        } else {
          const day = Math.floor((diff / (1000 * 60 * 60*24)));
          const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);
          setTimeLeft(`${day}d ${hours}h ${minutes}m ${seconds}s`);
        }
      }, 1000);
    } else {
      setTimeLeft(''); // Clear time left when voting is closed or end time is not set
    }
  
    return () => {
      clearInterval(timerInterval);
    };
  }, [config.votingOpen, config.votingEndTime]);
  
  const handleTitleChange = async () => {
    try {
      await ApiService.updateInspirationTitle(title);
      toast.success('Title updated successfully.');
      setEditingTitle(false);
    } catch (error) {
      console.error('Error updating title:', error);
      toast.error('Failed to update title.');
    }
  };

  const handleCreatePanel = async () => {
    if (!newPanel.imageUrl.trim()) {
      toast.error('Image URL is required.');
      return;
    }

    try {
      const response = await ApiService.createInspirationPanel(newPanel);
      setPanels((prevPanels) => [response.data.panel, ...prevPanels]);
      setNewPanel({ imageUrl: '' });
      toast.success('Panel created successfully.');
    } catch (error) {
      console.error('Error creating panel:', error);
      toast.error('Failed to create panel.');
    }
  };

  const handleEditPanel = async () => {
    if (!editingPanel.imageUrl.trim()) {
      toast.error('Image URL is required.');
      return;
    }

    try {
      const response = await ApiService.updateInspirationPanel(
        editingPanel.id,
        editingPanel
      );
      setPanels((prevPanels) =>
        prevPanels.map((panel) =>
          panel.id === editingPanel.id ? response.data.panel : panel
        )
      );
      setEditingPanel(null);
      toast.success('Panel updated successfully.');
    } catch (error) {
      console.error('Error updating panel:', error);
      toast.error('Failed to update panel.');
    }
  };

const updateConfig = async (updates) => {
  try {
    const response = await ApiService.updateInspirationConfig(updates);
    setConfig(response.data.config);

    // Update the title if it was changed
    if (updates.title !== undefined) {
      setTitle(response.data.config.title);
    }

    toast.success('Configuration updated.');
  } catch (error) {
    console.error('Error updating configuration:', error);
    toast.error('Failed to update configuration.');
  }
};
  
  const resetLikes = async () => {
    try {
      await ApiService.resetInspirationLikes();
      toast.success('All likes have been reset.');
      // Refresh panels
      const response = await ApiService.fetchInspirationPanels();
      setPanels(response.data.panels);
    } catch (error) {
      console.error('Error resetting likes:', error);
      toast.error('Failed to reset likes.');
    }
  };

  return (
    <>
      <Navbar isLoggedIn={isAuthenticated} accountName={userData?.fullName} />
      <ToastContainer />
      {isAdmin && (
        <div className="admin-toggle-container">
          <button onClick={() => setViewAsAdmin(!viewAsAdmin)}>
            {viewAsAdmin ? 'View as User' : 'View as Admin'}
          </button>
        </div>
      )}
      <div className="inspiration-vote-container">
        {loading ? (
          <Loader />
        ) : (
          <>
            {/* Voting Info Panel */}
            <div className="voting-info-panel">
              {viewAsAdmin && editingTitle ? (
                <div className="title-editor">
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <button onClick={handleTitleChange}>Save Title</button>
                  <button onClick={() => setEditingTitle(false)}>Cancel</button>
                </div>
              ) : (
                <h1 className="voting-title">
                  {title}{' '}
                  {viewAsAdmin && (
                    <button onClick={() => setEditingTitle(true)}>Edit</button>
                  )}
                </h1>
              )}
              <h2 className="voting-status">
                Voting Status: {config.votingOpen ? 'Open' : 'Closed'}
              </h2>
              {config.votingOpen && config.votingEndTime && (
                <div className="voting-timer">
                  <p>Time left to vote: {timeLeft}</p>
                </div>
              )}
            </div>

            {viewAsAdmin && (
              <div className="admin-control-panel">
                <h3>Admin Controls</h3>
                <div className="admin-control-item">
                  <label>Working Mode:</label>
                  <select
                    value={config.mode || 'normal'}
                    onChange={(e) => updateConfig({ mode: e.target.value })}
                  >
                    <option value="normal">Normal</option>
                    <option value="randomized">Randomized</option>
                  </select>
                </div>
                <div className="admin-control-item">
                  <label>Voting Status:</label>
                  <button onClick={() => updateConfig({ votingOpen: !config.votingOpen })}>
                    {config.votingOpen ? 'Close Voting' : 'Open Voting'}
                  </button>
                </div>
                <div className="admin-control-item">
                  <label>Voting End Time:</label>
                  <input
                    type="datetime-local"
                    value={
                      config.votingEndTime
                        ? new Date(config.votingEndTime).toISOString().substring(0, 16)
                        : ''
                    }
                    onChange={(e) => {
                      const newEndTime = new Date(e.target.value);
                      // Adjust for timezone offset
                      const utcEndTime = new Date(
                        newEndTime.getTime() + newEndTime.getTimezoneOffset() * 60000
                      );
                      updateConfig({ votingEndTime: utcEndTime.toISOString() });
                    }}
                  />
                </div>
                <button onClick={resetLikes}>Reset All Likes</button>
              </div>
            )}
            {viewAsAdmin && (
              <div className="admin-panel-form">
                <h3>{editingPanel ? 'Edit Panel' : 'Create Panel'}</h3>
                <input
                  type="text"
                  placeholder="Image URL"
                  value={editingPanel ? editingPanel.imageUrl : newPanel.imageUrl}
                  onChange={(e) =>
                    editingPanel
                      ? setEditingPanel({ ...editingPanel, imageUrl: e.target.value })
                      : setNewPanel({ ...newPanel, imageUrl: e.target.value })
                  }
                />
                <button onClick={editingPanel ? handleEditPanel : handleCreatePanel}>
                  {editingPanel ? 'Save Changes' : 'Create Panel'}
                </button>
                {editingPanel && (
                  <button onClick={() => setEditingPanel(null)}>Cancel</button>
                )}
              </div>
            )}
            <div className="panels-grid">
              {panels.map((panel) => (
                <InspirationPanel
                  key={panel.id}
                  panel={panel}
                  isAuthenticated={isAuthenticated}
                  userData={userData}
                  viewAsAdmin={viewAsAdmin}
                  setPanels={setPanels}
                  onEdit={setEditingPanel}
                  config={config} // Pass config to the panel
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InspirationVote;
